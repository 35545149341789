/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils 
   ------------------------
*/


.text-uppercase {
    @include uppercase;
}

.text-serif {
    font-family: $font-family-serif !important;
}

.text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

/*
     Colours and backgrounds
    ------------------------
*/


@each $color,
$value in $theme-colors {
    .bg-#{$color}-light {
        background-color: tint-color($value, 80%);
    }
}
@each $color,
$value in $colors {
    .bg-#{$color}-light {
        background-color: tint-color($value, 80%);
    }
}

@each $color,
$value in $theme-colors {
    .badge-#{$color}-light {
        color: $value;
        background-color: tint-color($value, 80%)
    }
}

/*
     Transparent overlays
    ------------------------
*/

.overlay-content {
    position: relative;
    z-index: 20;
}

// responsive overlay
@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .light-overlay#{$infix} {
        position: relative;

        @include media-breakpoint-up($next) {
            position: relative;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: .3;
                background: #fff;
            }
        }
    }

    .light-overlay#{$infix}-0 {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }

    .dark-overlay {
        position: relative;

        @include media-breakpoint-up($next) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: .3;
                background: #000;
            }
        }
    }

    .dark-overlay#{$infix}-0 {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }
}

/*
     Other
    ------------------------
*/

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.tile-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 30;
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

img.bg-image {
    font-family: 'object-fit: cover;';
    object-fit: cover;
}

.full-screen {
    height: calc(100vh - #{$headerHeight} - 2rem);
}

.mh-full-screen {
    min-height: 100vh;

    // hack for IE 11
    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        height: 100vh;
    }
}

.mh-full-screen-with-header {
    min-height: calc(100vh - #{$headerHeight} - 2rem);

    // hack for IE 11
    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        height: calc(100vh - #{$headerHeight} - 2rem);
    }
}

// responsive collapse behaviour
@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .expand#{$infix} {
        @include media-breakpoint-up($next) {
            display: block !important;
        }
    }
}

.block-toggler {
    display: block;
    position: relative;
    color: $text-muted;
    @include uppercase;

    &[aria-expanded="true"],
    &:focus {
        text-decoration: none;
        color: $body-color;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        color: $body-color;
    }

    &[data-bs-toggle="collapse"] {
        &::before {
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 0;
            content: '\f105';
            transform: translateY(-50%);
            font-family: "Font Awesome\ 5 Free";
            font-weight: 900;
        }
    }

    &[aria-expanded="true"] {
        &::before {
            content: '\f107';
        }
    }
}

.svg-icon {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    stroke: currentColor;
    stroke-width: 3;
    --layer1: currentColor;
    --layer2: currentColor;

    &.svg-icon-light {
        stroke-width: 2;
    }

    &.svg-icon-heavy {
        stroke-width: 4;
    }
}

// auto width for smallest viewports
.w-xs-auto {
    @include media-breakpoint-down(sm) {
        width: auto !important;
    }
}

// fixes for IE11
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {

    .card-img-top,
    .card-img-bottom,
    .card-img {
        height: 100%;
    }
}